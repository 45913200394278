// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
}
// REMIX HMR END

import { Form, useActionData } from "@remix-run/react";
import React, { useState } from "react";
import axios from 'axios';
import "../styles/booking_app.css?__remix_sideEffect__";
import bannerImage from "../media/bannerImage.png";

//international phone 
import "react-phone-number-input/style.css?__remix_sideEffect__";
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';

// Define a type for the action data

const getNextSevenDays = () => {
  const dates = [];
  for (let i = 0; i < 5; i++) {
    const date = new Date();
    date.setDate(date.getDate() + i);
    const weekday = date.toLocaleDateString('en-US', {
      weekday: 'long'
    });
    const dateString = date.toISOString().split('T')[0];
    dates.push({
      weekday,
      dateString
    });
  }
  return dates;
};
export default function Booking() {
  _s();
  const actionData = useActionData();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [activities, setActivities] = useState([]);
  const dates = getNextSevenDays(); // Generate the dates for the checkboxes
  const today = new Date().toISOString().split('T')[0]; // Generate today's date in YYYY-MM-DD format

  //form validation
  const [clientEmail, setClientEmail] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedActivity, setSelectedActivity] = useState(''); // Correctly placed
  const [selectedRoomName, setSelectedRoomName] = useState(''); // Correctly placed
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);

  // for splitting the name
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  //spliting phone field
  const [clientPhone, setClientPhone] = useState('');

  // Replace individual error states with a unified error state
  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    clientEmail: false,
    clientPhone: false
  });

  // activity dynamic selection
  const groups = {
    "CULINARY REPERTOIRE": ["Saporium", "Wine Tasting", "Grotto Dining", "Cookery Lessons", "Borgo Picnic", "Farmers’ Market", "Orto Pop-Up Dinner"],
    "ON THE FARM": ["Organic Honey Tasting & Beekeeping Demonstration", "Sheep Milking, Cheese Making & Cheese Tasting", "Farm Animals & Farm Tour", "Organic Egg collection", "Vegetable Collection"],
    "RECHARGE & REJUVENATE": ["Holistic Spa Treatments", "Yoga Lessons", "Guided Meditation Walks & Forest Bathing", "Gong Sound Bath", "Herb Walks & Garden Walks"],
    "SPORTS & ADVENTURE": ["Court Play", "Forest Trekking", "Cycling & Hiking", "Vespa Tours & Fiat 500"],
    "ARTS & CULTURE": ["Art Lessons", "Floristry", "Movie Nights & Live Music", "Falconry Display"],
    "BEYOND BORGO": ["Eroica Vintage Bike Tour", "Go Golfing", "Horse-Riding", "Tuscan Truffle Hunt", "The Sword In The Stone Walk", "Montalcino Gourmet Wine Tour", "Chianti Classico Wine Tour", "La Dolce Vita", "Shopping In Florence", "Hot Air Ballooning"]
  };

  // Define the time options
  const timeOptions = ["Morning", "Lunch", "Afternoon", "Dinner", "Evening"];

  // Combine all activities into a single array
  const allActivities = Object.values(groups).flat();

  // Create a union type from the array elements

  const timeAvailability = {
    "Grotto Dining": ["Lunch", "Dinner"],
    "Cookery Lessons": ["Morning", "Evening"],
    "Saporium": ["Lunch", "Dinner"],
    "Wine Tasting": ["Afternoon"],
    "Sheep Milking, Cheese Making & Cheese Tasting": ["Afternoon", "Morning"],
    "Organic Egg collection": ["Evening"],
    "Vegetable Collection": ["Morning"],
    "Forest Trekking": ["Morning"]

    // Add more activities and their available times here
  };
  const handleActivityChange = event => {
    const activity = event.target.value; // Cast to Activity type

    setSelectedActivity(activity);

    // Check if the activity is a valid key before using it
    if (activity in timeAvailability) {
      const availableTimes = timeAvailability[activity];
      setAvailableTimeOptions(availableTimes);

      // If the currently selected time is not available for this activity, reset the selected time
      if (!availableTimes.includes(selectedTime || "")) {
        setSelectedTime(null);
      }
    } else {
      // Handle the case where the activity is not in the timeAvailability map
      setAvailableTimeOptions(timeOptions); // Default to all time options
    }
  };
  const [availableTimeOptions, setAvailableTimeOptions] = useState(timeOptions);
  const validateName = (firstName, lastName) => {
    return !firstName.trim() || !lastName.trim(); // Checks if either name is empty or just whitespace
  };
  const validateFirstName = firstName => {
    return !firstName.trim(); // Checks if firstName is empty or just whitespace
  };
  const validateLastName = lastName => {
    return !lastName.trim(); // Checks if lastName is empty or just whitespace
  };
  const validateEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return !emailRegex.test(email); // Returns true if email does NOT match the regex (indicating an error)
  };

  // const validatePhone = (phone: string) => {
  const validatePhone = phone => {
    return phone ? !isValidPhoneNumber(phone) : false; // false when phone is empty
  };

  //for split name 
  const handleFirstNameChange = event => {
    const newFirstName = event.target.value;
    setFirstName(newFirstName);
    setFormErrors(prevErrors => ({
      ...prevErrors,
      firstName: validateFirstName(newFirstName) // Use validateFirstName here
    }));
  };
  const handleLastNameChange = event => {
    const newLastName = event.target.value;
    setLastName(newLastName);
    setFormErrors(prevErrors => ({
      ...prevErrors,
      lastName: validateLastName(newLastName) // Use validateLastName here
    }));
  };

  // Repeat for handleEmailChange and handlePhoneChange
  const handleEmailChange = event => {
    const newEmail = event.target.value;
    setClientEmail(newEmail);
    setFormErrors(prevErrors => ({
      ...prevErrors,
      clientEmail: validateEmail(newEmail)
    }));
  };
  const handlePhoneChange = value => {
    setClientPhone(value);
    // If 'value' is undefined, default it to an empty string before passing to 'isValidPhoneNumber'
    const phoneError = value ? !isValidPhoneNumber(value) : true;
    setFormErrors(prevErrors => ({
      ...prevErrors,
      clientPhone: phoneError
    }));
  };

  // to select the whole box
  const toggleDateSelection = date => {
    setSelectedDates(prevSelectedDates => {
      if (prevSelectedDates.includes(date)) {
        return prevSelectedDates.filter(d => d !== date); // Remove the date if it's already selected
      } else {
        return [...prevSelectedDates, date]; // Add the date if it's not already selected
      }
    });
  };

  // Adjust the time selection to include a hidden input field
  const selectTime = time => {
    setSelectedTime(prevSelectedTime => prevSelectedTime === time ? null : time);
  };
  const handleGroupChange = event => {
    const group = event.target.value;
    setSelectedGroup(group);
    setActivities(groups[group] || []);
  };
  const handleRoomNameChange = event => {
    setSelectedRoomName(event.target.value);
  };

  // Update the isSubmitted state based on the actionData
  React.useEffect(() => {
    if (actionData?.message) {
      setIsSubmitted(true);
    }
  }, [actionData]);
  const handleSubmit = async event => {
    event.preventDefault();

    // Validate all fields and update formErrors
    const errors = {
      firstName: validateFirstName(firstName),
      lastName: validateLastName(lastName),
      clientEmail: validateEmail(clientEmail),
      clientPhone: validatePhone(clientPhone)
    };
    setFormErrors(errors);

    // Check for any errors
    const hasErrors = Object.values(errors).some(error => error);
    if (hasErrors) {
      // Log a message if there are form validation errors
      console.log("Form validation failed:", errors);
      return; // Exit the function to prevent further execution
    }

    // Construct a simple object with the form data
    const data = {
      client: `${firstName} ${lastName}`,
      clientEmail: clientEmail,
      clientPhone: clientPhone,
      // Optional field
      activityGroup: selectedGroup,
      activity: selectedActivity,
      dates: selectedDates,
      time: selectedTime || '',
      roomName: selectedRoomName
    };
    console.log("Form data:", data);
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      // Perform the form submission via axios
      //for local
      // const response = await axios.post('http://localhost:3001/api/forms', JSON.stringify(data), config);
      //for deploy
      const response = await axios.post('https://reservations.borgosantopietro.com/api/forms', JSON.stringify(data), config);
      if (response.status === 200) {
        console.log("Form submitted successfully:", response.data);
        setIsSubmitted(true);
      } else {
        // Log non-200 responses as well
        console.error(`Submission failed with status ${response.status}:`, response.statusText);
      }
    } catch (error) {
      // Log more details in case of an error
      console.error("Error during form submission:", error);
      if (axios.isAxiosError(error) && error.response) {
        // If the error is an AxiosError, log response details
        console.error("Error details:", error.response.status, error.response.data);
      }
    }
  };
  return <div className="formContainer">
      <div className="formBannerContainer">
        <img className="formBannerImage" src={bannerImage} alt="Banner Image" />
      </div>
      <div className="form">

        {isSubmitted ? <div className="thankYouMessage">
            <h2 className="colorGreenTea">Thank You!</h2>
            <p className="colorGreenTea">Your request has been sent successfully.<br /> One of our team members will contact you shortly to confirm.</p>
            <button onClick={() => window.location.reload()} className="button">Make another request</button>
            {actionData?.message && <p className="confirmationLine" id="confirmationLine">{actionData.message}<br /><br /><a href="#" onClick={e => {
            e.preventDefault();
            window.location.reload();
          }}>Click here</a> to make another reservation request.</p>}

          </div> : <Form method="post" onSubmit={handleSubmit}>

            <div className="formField activitiesSelectorBox">


              <div className="activitiesSelectorItem inputFieldContainer">

                <select name="activityGroup" id="group" onChange={handleGroupChange}>
                  <option value="">ACTIVITY GROUP</option>
                  {Object.keys(groups).map(group => <option key={group} value={group}>{group}</option>)}
                </select>
              </div>
              <div className="activitiesSelectorItem inputFieldContainer">

                <select name="activity" id="activity" onChange={handleActivityChange} disabled={!selectedGroup}>
                  <option value="">ACTIVITY</option>
                  {activities.map(activity => <option key={activity} value={activity}>{activity}</option>)}
                </select>
              </div>

            </div>

          <div className="formField marginTop marginBottom">
  <label className="centerText">SELECT DATES</label>
  <div className="dateContainer">
    {dates.map(({
              weekday,
              dateString
            }) => {
              // Destructure the properties here
              const value = dateString; // Use dateString directly
              const isSelected = selectedDates.includes(value);
              const dateObject = new Date(value);
              const month = dateObject.toLocaleString('default', {
                month: 'short'
              }).toUpperCase(); // Get month abbreviation in uppercase
              const day = dateObject.getDate(); // Get day of the month
              return <div key={value} className={`dateLine ${isSelected ? 'selected' : ''}`}>
          <label htmlFor={value} className="activitiesLabel" onClick={() => toggleDateSelection(value)}>
            {weekday} <br />  <span className="dayDateFont"> {month} {day < 10 ? `0${day}` : day}</span> {/* Pad single digit days */}
          </label>
          <input type="checkbox" id={value} name="date" value={value} checked={isSelected} readOnly hidden />
        </div>;
            })}
  </div>
          </div>


            <div className="formField marginTop marginBottom">
              <label className="centerText">SELECT TIME</label>

              <div className="timeContainer">
                {timeOptions.map(time => {
              const isActive = availableTimeOptions.includes(time);
              return <div key={time} className={`timeOption ${selectedTime === time ? 'selected' : ''} ${!isActive ? 'inactive' : ''}`} onClick={() => isActive && selectTime(time)}>

                      {time}
                    </div>;
            })}
              </div>
              {/* Hiden selector to update  */}
              <input type="hidden" name="time" value={selectedTime || ''} />
            </div>



            <div className="nameInputContainer marginTop">
              <div className={`formField inputFieldContainer ${formErrors.firstName ? "error" : ""}`}>
                <input className="textInput" placeholder="FIRST NAME" id="firstName" name="firstName" required autoComplete="given-name" value={firstName} onChange={handleFirstNameChange} />

              </div>

              <div className={`formField inputFieldContainer ${formErrors.lastName ? "error" : ""}`}>
                <input className="textInput" placeholder="LAST NAME" id="lastName" name="lastName" required autoComplete="family-name" value={lastName} onChange={handleLastNameChange} />

              </div>
            </div>


            <div className="formField ">

            </div>

            <div className="nameInputContainer">



              <div className={`formField inputFieldContainer ${formErrors.clientEmail ? "error" : ""}`}>
                <textarea className="textInput" placeholder="youremail@yourprovider.com" id="clientEmail" name="clientEmail" rows={1} required autoComplete="email" value={clientEmail} onChange={handleEmailChange}>
                </textarea>
              </div>



              <div className={`formField inputFieldContainer ${formErrors.clientPhone ? "error" : ""}`}>
                <PhoneInput international defaultCountry="IT" value={clientPhone} onChange={handlePhoneChange} // This now correctly uses handlePhoneChange
            className="textInput" placeholder="PHONE (Optional)" />

              </div>



            </div>

            <div className="formField ">

            </div>


            <div className="buttonContainer">

              <div className="formField inputFieldContainer">

                <select name="roomName" id="roomName" onChange={handleRoomNameChange} value={selectedRoomName}>
                  <option value="Unselected">ROOM OR SUITE</option>
                  <option value="Sir Vincent Delux Room">Sir Vincent Delux Room</option>
                  <option value="San Galgano Deluxe Room">San Galgano Deluxe Room</option>
                  <option value="Giardin dè Fiori Junior Suite">Giardin dè Fiori Junior Suite</option>
                  <option value="Via del Pellegrino Junior Suite">Via del Pellegrino Junior Suite</option>
                  <option value="Rinaldo di Sorciano Suite">Rinaldo di Sorciano Suite</option>
                  <option value="Studio Badia Junior Suite">Studio Badia Junior Suite</option>
                  <option value="Valle Serena Suite">Valle Serena Suite</option>
                  <option value="Il Vecchio Forno Suite">Il Vecchio Forno Suite</option>
                  <option value="Santo Pietro Grand Suite">Santo Pietro Grand Suite</option>
                  <option value="La Mora Junior Garden Suite">La Mora Junior Garden Suite</option>
                  <option value="L’Ulivo Junior Garden Suite">L’Ulivo Junior Garden Suite</option>
                  <option value="La Casa dell’Unicorno Garden Suite">La Casa dell’Unicorno Garden Suite</option>
                  <option value="Casa Ellen">Casa Ellen</option>
                  <option value="Dea Feronia Garden Suite">Dea Feronia Garden Suite</option>
                  <option value="Il Melograno Garden Suite">Il Melograno Garden Suite</option>
                  <option value="Maggiorana Garden Suite">Maggiorana Garden Suite</option>
                  <option value="Rosmarino Garden Suite">Rosmarino Garden Suite</option>
                  <option value="Basilico Garden Suite">Basilico Garden Suite</option>
                  <option value="Dina Ferri Pool House">Dina Ferri Pool House</option>
                  <option value="Casa Gerda Pool House">Casa Gerda Pool House</option>
                  <option value="La Casa dei Fiori Bianchi Pool House">La Casa dei Fiori Bianchi Pool House</option>
                </select>

              </div>


              <button className={`button ${Object.values(formErrors).some(error => error) ? 'disabled' : ''}`} type="submit"
          // Ensure button is enabled initially and gets disabled based on client-side validation
          disabled={Object.values(formErrors).some(error => error)}>

                {isSubmitted ? "Thank You" : "SEND REQUEST"}
              </button>

            </div>


          </Form>}



      </div>
    </div>;
}
_s(Booking, "TQ9DLRp00c4cet+klFZBS86IA18=", false, function () {
  return [useActionData];
});
_c = Booking;
var _c;
$RefreshReg$(_c, "Booking");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;